export const mergeProductAndVariantImages = (data) => {
  const allImages = [...(data.images || [])];
  const imageUrls = new Set(allImages.map((img) => img.url));

  let nextId =
        allImages.length > 0
          ? Math.max(...allImages.map((img) => parseInt(img.id) || 0)) + 1
          : 1;

  if (data.variants && Array.isArray(data.variants)) {
    data.variants.forEach((variant) => {
      if (variant.image && !imageUrls.has(variant.image)) {
        allImages.push({
          id: nextId++,
          url: variant.image,
          src: variant.image,
          height: null,
          width: null,
          variant_ids: [variant.id],
        });

        imageUrls.add(variant.image);
      }
    });
  }

  return allImages;
};
