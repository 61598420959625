import axios from 'axios';
import store from '@/store';

// We need to grab this at runtime because this is not available when the code is first run
const getId = () => store.state.shopContents.id;

export default {
  async lookup(payload) {
    const shopId = getId();
    return await axios.post(`api/v1/${shopId}/order/lookup`, payload);
  },
  async lookupByOrderName(payload) {
    const shopId = getId();
    return await axios.post(`api/v1/${shopId}/order/lookupbyordername`, payload);
  },
  async giftReceiptLookup(payload) {
    const shopId = getId();
    return await axios.post(`api/v1/${shopId}/gift-receipt/lookup`, payload);
  },
  async giftRecipientReceiptLookup(payload) {
    const shopId = getId();
    return await axios.post(`api/v1/${shopId}/gift-receipt/link`, payload);
  },
  async generateGiftQRCode(payload) {
    const shopId = getId();
    return await axios.post(`api/v1/${shopId}/gift-receipt/qr`, payload);
  },
  async deleteGiftReceipt({ id }) {
    const shopId = getId();
    return await axios.delete(`api/v1/${shopId}/gift-receipt/${id}/delete`);
  },
  async shareGiftReceipt(payload) {
    const shopId = getId();
    const { giftReceiptId, ...rest } = payload;

    return await axios.post(`api/v1/${shopId}/gift-receipt/${giftReceiptId}/send`, rest);
  },
  getLineItem(lineItemId, params = {}) {
    const shopId = getId();
    return axios.get(`api/v1/${shopId}/order/${lineItemId}/line_item`, { params })
      .then((res) => res.data);
  },
  markInStore(id, value) {
    const shopId = getId();
    return axios.post(`api/v1/${shopId}/order/${id}/returninstore`, { value });
  },
  updateAddress(id, address) {
    const shopId = getId();
    return axios.post(`api/v1/${shopId}/order/${id}/address`, address);
  },
  async intentInitiate(payload) {
    const shopId = getId();

    const response = await axios
      .post(`api/payment/${shopId}/intent/initiate`, payload);
    return response.data;
  },
};
