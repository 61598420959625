<template>
  <div
    v-if="needsPayment || needsAuthorization || differenceCovered"
    class="payment-details"
  >
    <template
      v-if="needsPayment || needsAuthorization"
    >
      <div
        v-if="acceptsPayment"
        class="payment-details__container"
      >
        <shopify-payments-input
          v-if="checkoutType === 'shopify-payments'"
          class="payment-details__shopify-payments"
          :disabled="disabled"
          @update="(token) => update(token)"
        />
        <stripe-input3ds
          v-else-if="hasStripe3dsFF && !hasUpsellWithInstantOutcome"
          v-bind="{ isInstantOutcome, totals }"
          class="payment-details__stripe"
          :disabled="disabled"
          @update3DS="(stripe, elements, paymentIntentId, setupIntentId) => update3DS(stripe, elements, paymentIntentId, setupIntentId)"
        />
        <stripe-input
          v-else
          class="payment-details__stripe"
          :disabled="disabled"
          v-bind="{ isInstantOutcome }"
          @change="change"
          @update="(token) => update(token)"
          @submit="onSubmit"
        />
        <base-text
          v-if="needsAuthorization"
          class="payment-details__fineprint"
          type="body-2"
        >
          <render-content
            v-if="hasInstantRefundsFF && this.return.creditType === returnCreditTypeRefund"
            :data="{ amountToAuthorize, amountToCharge, instantReturnWindow }"
          >
            {{ $content.modulePaymentDetails.authorizationCopyInstantReturn }}
          </render-content>
          <render-content
            v-else
            :data="{ amountToAuthorize, amountToCharge, instantExchangeWindow }"
          >
            {{ $content.modulePaymentDetails.authorizationCopyInstantExchange }}
          </render-content>
        </base-text>
      </div>
      <base-text
        v-else
        class="payment-details__overage"
      >
        <render-content>
          {{ $content.pageReview.overageWarningCopy }}
        </render-content>
      </base-text>
    </template>
    <base-text
      v-if="differenceCovered"
      class="payment-details__message"
    >
      <render-content>
        {{ $content.pageReview.differenceCovered }}
      </render-content>
    </base-text>
  </div>
</template>

<script>
import { BaseText } from '@loophq/design-system';
import { formatCurrency } from '@/js/helpers/formatCurrency';
import { featureFlags } from '@/js/constants/featureFlags';
import { mapState } from 'vuex';
import StripeInput from '@/components/forms/StripeInput';
import StripeInput3ds from '@/components/forms/StripeInput3DS.vue';
import ShopifyPaymentsInput from '@/components/forms/ShopifyPaymentsInput';
import {
  returnTypes,
  returnCreditTypes
} from '@/js/constants/returns';

export default {
  components: {
    StripeInput3ds,
    BaseText,
    ShopifyPaymentsInput,
    StripeInput
  },
  props: {
    acceptsPayment: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    totals: {
      type: Object,
      required: false
    },
    exchangeType: {
      type: String,
      required: false
    },
    returnType: {
      type: String,
      required: false
    },
    checkoutType: {
      type: String,
      required: false
    }
  },
  emits: ['update', 'update3DS', 'change', 'submit'],
  computed: {
    ...mapState([
      'return'
    ]),
    needsPayment() {
      return (
        this.totals.computed.net < 0 &&
        this.totals.computed.net * -1 > this.totals.settings.stripeMin
      );
    },
    differenceCovered() {
      return (
        this.totals.computed.net < 0 &&
        this.totals.computed.net * -1 < this.totals.settings.stripeMin
      );
    },
    needsAuthorization() {
      if (
        this.hasInstantRefundsFF &&
        this.hasInstantReturns &&
        this.returnType === returnTypes.INSTANT
      ) {
        return true;
      }

      return (
        this.hasInstantExchanges &&
        this.totals.owed.exchanges.amountToAuthorize &&
        this.totals.owed.exchanges.amountToCharge &&
        this.exchangeType === returnTypes.INSTANT
      );
    },
    hasInstantExchanges() {
      return this.totals.owed.exchanges.instantExchangeEnabled;
    },
    instantExchangeWindow() {
      return this.totals.owed.exchanges.instantExchangeWindow;
    },
    hasInstantReturns() {
      return this.totals.owed.instantReturnEnabled;
    },
    instantReturnWindow() {
      return this.totals.owed.instantReturnWindow;
    },
    isInstantOutcome() {
      return this.exchangeType === returnTypes.INSTANT;
    },
    shopCurrency() {
      return this.$store.getters.settings.currency;
    },
    amountToCharge() {
      const amountToCharge = this.hasInstantRefundsFF ? this.totals.owed.amountToCharge : this.totals.owed.exchanges.amountToCharge;
      return formatCurrency(
        amountToCharge,
        this.shopCurrency
      );
    },
    amountToAuthorize() {
      const amountToAuthorize = this.hasInstantRefundsFF ? this.totals.owed.amountToAuthorize : this.totals.owed.exchanges.amountToAuthorize;
      return formatCurrency(
        amountToAuthorize,
        this.shopCurrency
      );
    },
    hasInstantRefundsFF() {
      return this.$store.getters.hasFeature(featureFlags.INSTANT_REFUNDS);
    },
    returnCreditTypeRefund() {
      return returnCreditTypes.REFUND;
    },
    hasStripe3dsFF() {
      return this.$store.getters.hasFeature(featureFlags.PAYMENTS_STRIPE_3DS2);
    },
    hasUpsellWithInstantOutcome() {
      return this.needsPayment && this.isInstantOutcome;
    },
  },
  methods: {
    change(event) {
      this.$emit('change', event);
    },
    update(token) {
      this.$emit('update', token);
    },
    update3DS(stripe, elements, paymentIntentId, setupIntentId) {
      this.$emit('update3DS', stripe, elements, paymentIntentId, setupIntentId);
    },
    onSubmit() {
      this.$emit('submit');
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.payment-details';

#{$block} {
  &__message {
    color: var(--grey-700);
  }

  &__fineprint {
    color: var(--grey-700);
    padding-top: var(--spacing-200);
  }

  &__overage {
    border-radius: var(--corners);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 20%);
    padding: var(--spacing-300) calc(var(--spacing-300) + var(--corners));
    background-color: var(--red-300);
    color: white;

    a {
      color: inherit;
    }
  }
}
</style>
