<template>
  <div
    class="stripe-input"
    :class="{ disabled }"
    data-testid="stripe-input"
  >
    <form
      class="stripe-input__element"
      :class="{ error: !!error, focused }"
    >
      <div
        v-if="loading"
        class="stripe-input__spinner"
      >
        <base-loader />
      </div>
      <div
        ref="element"
      >
        <!-- Stripe will create form elements here -->
      </div>
    </form>
    <transition name="stripe-input">
      <p
        v-if="error"
        class="stripe-input__error"
      >
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script setup>
import env from '@/env';
import Order from '@/js/controllers/order';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import BaseLoader from '@/components/feedback/BaseLoader.vue';
import { featureFlags } from '@/js/constants/featureFlags';
const store = useStore();

const props = defineProps({
  isInstantOutcome: {
    type: Boolean,
    default: false,
  },
  totals: {
    type: Object,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  },
});

const emit = defineEmits(['update3DS']);

const loading = ref(true);
const error = ref(false);
const focused = ref(false);
const element = ref(false);
const input = ref(false);

const receipt = computed(() => store.state?.totals?.receipt);

const hasInstantRefundsFF = computed(() => {
  return store.getters.hasFeature(featureFlags.INSTANT_REFUNDS);
});

const hasEnableStripeSetupIntentFF = computed(() => {
  return store.getters.hasFeature(featureFlags.ENABLE_STRIPE_SETUP_INTENT);
});

const amountToAuthorize = () => {
  return hasInstantRefundsFF.value ? props.totals.owed.amountToAuthorize : props.totals.owed.exchanges.amountToAuthorize;
};

onMounted(() => {
  buildStripeElement(props.isInstantOutcome);
});

watch(
  () => props.isInstantOutcome,
  (newVal) => {
    validate(newVal, null, null, {});
  }
);

const validate = (isInstantOutcome, paymentIntentId, setupIntentId, event) => {
  if (!paymentIntentId && !setupIntentId) {
    return buildStripeElement(isInstantOutcome);
  }

  if (event.error?.message) {
    error.value = event.error.message;
    return;
  }
  error.value = null;
};

const buildStripeElement = () => {
  const amount =  props.isInstantOutcome ? amountToAuthorize() : Math.abs(props.totals.computed.net);
  const captureMethod =  props.isInstantOutcome ? 'manual' : 'automatic';
  const payload = {
    amount: amount,
    currency: receipt.value.currency,
    capture_method: captureMethod
  };
  if (hasEnableStripeSetupIntentFF.value) {
    payload.order_id = store.state.order.id;
  }
  Order.intentInitiate(payload)
    .then(response => {
      let paymentIntentId = null;
      let setupIntentId = null;
      if (hasEnableStripeSetupIntentFF.value) {
        paymentIntentId = response.payment_intent_id ?? null;
        setupIntentId = response.setup_intent_id ?? null;
      } else {
        paymentIntentId = response.intent_id;
      }

      const clientSecret = response.client_secret;
      const stripeAccount = response.stripe_account;

      const stripe = Stripe(env('VITE_STRIPE_KEY'), {
        stripeAccount: stripeAccount,
      });
      const appearance = {
        variables: {
          fontSizeBase: '16px'
        }
      };

      const options = {
        layout: {
          type: 'accordion',
          defaultCollapsed: false,
          radios: false,
          spacedAccordionItems: true
        }
      };

      const elements = stripe.elements({
        clientSecret: clientSecret,
        appearance
      });

      input.value = elements.create('payment', options);
      input.value.mount(element.value);
      input.value.on('ready', () => {
        loading.value = false;
      });
      input.value.on('change', async (event) => {
        if (event.complete) {
          emit('update3DS', stripe, elements, paymentIntentId, setupIntentId);
        }
        validate(props.isInstantOutcome, paymentIntentId, setupIntentId, event);
      });
    }).catch(e => {
      if (e?.message) {
        error.value = e.message;
      }
    });
};
</script>

<style lang="scss" scoped>
$block: '.stripe-input';

#{$block}-enter, #{$block}-leave-to {
  transform: translate3d(0, 3px, 0);
}

#{$block}-enter-active, #{$block}-leave-active {
  transition: transform var(--easing-regular);
}

#{$block} {
  &__element {
    margin: 0;
    padding: var(--spacing-300);
    border: 1px solid var(--grey-300);
    border-radius: var(--corners);
    box-shadow: 0 0 0 1px transparent;
    transition: border-color var(--transition-300), box-shadow var(--transition-300);
    appearance: none;
    background: white;

    &:hover {
      border-color: var(--grey-700);
    }

    &.error {
      border-color: var(--red-300);
      box-shadow: 0 0 0 1px var(--red-300);
    }

    // We have to implement this as a class because the stripe element
    // is in an iframe and we can't target the input directly
    &.focused {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
    }

    &:disabled {
      background-color: var(--grey-100) !important;
      color: var(--grey-600) !important;
      cursor: not-allowed !important;
      border-color: transparent !important;
      box-shadow: 0 0 0 1px transparent;
    }
  }

  &__spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__error {
    color: var(--red-300);
    padding: var(--spacing-100) 0;
  }
}
</style>
